<template>
  <a-spin :spinning="spinShow">
    <a-form class="form" :form="form">
      <a-row class="form-row" :gutter="16">
        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="授权类型">
            <a-radio-group :options="AuthType" @change="authTypeChange" :disabled="type==='edit'"
                           v-decorator="['type',{initialValue: 'organization_job',rules:[{type:'string',required:true,message:'类型为必填项'}],trigger:'change'}]"></a-radio-group>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="form-row" :gutter="16" v-if="formItem.type==='organization' || formItem.type==='organization_job'">
        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="组织机构">
            <a-tree-select
              style="width: 100%"
              v-decorator="[
                  'organizationIds',
                  {
                    validateTrigger: ['change'],
                    rules: [{
                      type:'array',
                      required: true,
                      message: '请选择组织机构',
                    }]
                  }
                ]"
              :treeData="organizations"
              treeDefaultExpandAll
              showCheckedStrategy="SHOW_ALL"
              :treeCheckStrictly="true"
              multiple
              treeCheckable
              treeNodeFilterProp="title"
              :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
              placeholder="请选择组织机构"
            ></a-tree-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="form-row" :gutter="16" v-if="formItem.type==='user'">
        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="用户">
            <a-tree-select
              v-decorator="[
                'userIds',
                {
                  validateTrigger: ['change'],
                  rules: [{
                    type: 'array',
                    required: true,
                    message: '请选择用户',
                  }],initialValue:[]
                }
              ]"
              treeDefaultExpandAll
              multiple
              treeNodeFilterProp="title"
              treeCheckable
              style="width: 100%"
              :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
              :treeData="orgUserTreeData"
              placeholder="请选择用户"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="form-row" :gutter="16" v-if="formItem.type==='organization_job'">
        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item key="chooseJob" label="工作职务">
            <a-select style="width: 100%"
                      v-decorator="[
                        'jobId',
                        {
                          validateTrigger: ['change', 'blur'],
                          rules: [{
                            required: true,
                            message: '请选择工作职务',
                          }]
                        }
                      ]"
                      optionFilterProp="children"
                      :disabled="type==='edit'"
                      placeholder="请选择工作职务">
              <a-select-option
                v-for="item in jobs"
                :key="'job-'+item.id"
                :title="item.name"
                :value="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="form-row" :gutter="16">
        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="角色">
            <a-select style="width: 100%"
                      v-decorator="[
                        'roleIds',
                        {
                          validateTrigger: ['change', 'blur'],
                          rules: [{
                            required: true,
                            message: '请选择角色',
                          }],
                        }
                      ]"
                      mode="multiple"
                      optionFilterProp="children"
                      placeholder="请选择角色">
              <a-select-option
                v-for="item in roles"
                :key="'role-'+item.id"
                :title="item.name"
                :value="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-spin>
</template>

<script>
  /* eslint-disable promise/param-names,null,no-undef */


  import SERVICE_URLS from '../../../api/service.url'
  import Vue from 'vue'

  export default {
    name: 'AuthForm',
    props: {
      type: {
        type: String,
        default: 'add'
      }
    },
    data () {
      return {
        options: [],
        spinShow: false,
        form: this.$form.createForm(this),
        ifSub: false,
        users: [],
        orgUserTreeData: [],
        treeExpandedKeys: [],
        organizations: [],
        jobs: [],
        roles: [],
        chooseJob: undefined,
        formItem: {
          type: 'organization_job',
          userIds: [],
          organizationIds: [],
          jobIds: [],
          roleIds: []
        },
        AuthType: [
          { value: 'user', label: '用户' },
          { value: 'organization', label: '组织机构' },
          { value: 'organization_job', label: '工作职务' }
        ]
      }
    },
    methods: {
      authTypeChange (e) {
        this.formItem.type = e.target.value
        this.formItem.userIds = []
        this.formItem.organizationIds = []
        this.formItem.jobIds = []
      },
      viewData (id) {
        this.formItem.userIds = []
        this.formItem.organizationIds = []
        this.formItem.jobIds = []
        this.loadData()
        this.form.resetFields()
        this.$http(this, {
          url: SERVICE_URLS.auth.viewAuth,
          params: { id },
          loading: 'spinShow',
          noTips: true,
          success: (data) => {
            Object.assign(this.formItem, data.body)
            this.formItem.userIds.push(data.body.userId)
            this.formItem.organizationIds.push(data.body.organizationId)
            this.formItem.jobIds.push(data.body.jobId)
            this.form.setFieldsValue({
              type: this.formItem.type,
              userIds: this.formItem.userIds,
              organizationIds: this.formItem.organizationIds,
              jobIds: this.formItem.jobIds,
              roles: this.formItem.roles
            })
          }
        })
      },
      loadData () {
        this.form.resetFields()
        this.spinShow = true
        Promise.all([
          Vue.http.get(SERVICE_URLS.auth.createInit.path),
          Vue.http.get(SERVICE_URLS.organization.orgUserTree.path),
          Vue.http.get(SERVICE_URLS.organization.antTree.path)
        ]).then(res => {
          let success = true
          if (res[0].code === 0) {
            this.roles = res[0].body.roles
            this.users = res[0].body.users
            this.jobs = res[0].body.jobs
          } else {
            success = false
          }
          if (res[1].code === 0) {
            this.orgUserTreeData = res[1].body
          } else {
            success = false
          }
          if (res[2].code === 0) {
            this.organizations = res[2].body
          } else {
            success = false
          }
          if (!success) {
            this.$message.error('角色分派数据初始化错误')
          }
          this.spinShow = false
        }).catch(error => {
          this.spinShow = false
          this.$message.error('角色分派数据初始化错误')
        })
      },
      submitAdd () {
        this.form.validateFields((err, values) => {
          if (!err) {
            this.setVOFields(values)
            this.$emit('submitStart', 'add')
            this.$http(this, {
              url: SERVICE_URLS.auth.create,
              data: this.formItem,
              success: (data) => {
                //添加成功，清空表单数据，并触发回调
                this.$emit('addSuccess', data.body)
                //清空表单数据
                this.formItem.userIds = []
                this.formItem.organizationIds = []
                this.formItem.jobIds = []
                this.formItem.roleIds = []
              },
              error: () => {
                this.$emit('addError')
              }
            })
          }
        })
      },
      setVOFields (values) {
        //处理UserId信息
        this.formItem.userIds = []
        if (values.userIds) {
          values.userIds.forEach(x => {
            this.formItem.userIds.push(x.split('_')[2])
          })
        }
        this.formItem.organizationIds = []
        if (values.organizationIds) {
          values.organizationIds.forEach(x => {
            this.formItem.organizationIds.push(x.value)
          })
        }
        this.formItem.jobIds = [values.jobId]
        this.formItem.roleIds = values.roleIds
      },
      treeLoad (treeNode) {
        return new Promise(resolve => {
          console.log(treeNode)
          // resolve()
        })
      },
      treeSelectChange () {
        console.log('>>>>')
      }
    }
  }
</script>