var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-spin',{attrs:{"spinning":_vm.spinShow}},[_c('a-form',{staticClass:"form",attrs:{"form":_vm.form}},[_c('a-row',{staticClass:"form-row",attrs:{"gutter":16}},[_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"授权类型"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['type',{initialValue: 'organization_job',rules:[{type:'string',required:true,message:'类型为必填项'}],trigger:'change'}]),expression:"['type',{initialValue: 'organization_job',rules:[{type:'string',required:true,message:'类型为必填项'}],trigger:'change'}]"}],attrs:{"options":_vm.AuthType,"disabled":_vm.type==='edit'},on:{"change":_vm.authTypeChange}})],1)],1)],1),(_vm.formItem.type==='organization' || _vm.formItem.type==='organization_job')?_c('a-row',{staticClass:"form-row",attrs:{"gutter":16}},[_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"组织机构"}},[_c('a-tree-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'organizationIds',
                {
                  validateTrigger: ['change'],
                  rules: [{
                    type:'array',
                    required: true,
                    message: '请选择组织机构',
                  }]
                }
              ]),expression:"[\n                'organizationIds',\n                {\n                  validateTrigger: ['change'],\n                  rules: [{\n                    type:'array',\n                    required: true,\n                    message: '请选择组织机构',\n                  }]\n                }\n              ]"}],staticStyle:{"width":"100%"},attrs:{"treeData":_vm.organizations,"treeDefaultExpandAll":"","showCheckedStrategy":"SHOW_ALL","treeCheckStrictly":true,"multiple":"","treeCheckable":"","treeNodeFilterProp":"title","dropdownStyle":{ maxHeight: '400px', overflow: 'auto' },"placeholder":"请选择组织机构"}})],1)],1)],1):_vm._e(),(_vm.formItem.type==='user')?_c('a-row',{staticClass:"form-row",attrs:{"gutter":16}},[_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"用户"}},[_c('a-tree-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'userIds',
              {
                validateTrigger: ['change'],
                rules: [{
                  type: 'array',
                  required: true,
                  message: '请选择用户',
                }],initialValue:[]
              }
            ]),expression:"[\n              'userIds',\n              {\n                validateTrigger: ['change'],\n                rules: [{\n                  type: 'array',\n                  required: true,\n                  message: '请选择用户',\n                }],initialValue:[]\n              }\n            ]"}],staticStyle:{"width":"100%"},attrs:{"treeDefaultExpandAll":"","multiple":"","treeNodeFilterProp":"title","treeCheckable":"","dropdownStyle":{ maxHeight: '400px', overflow: 'auto' },"treeData":_vm.orgUserTreeData,"placeholder":"请选择用户"}})],1)],1)],1):_vm._e(),(_vm.formItem.type==='organization_job')?_c('a-row',{staticClass:"form-row",attrs:{"gutter":16}},[_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{key:"chooseJob",attrs:{"label":"工作职务"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'jobId',
                      {
                        validateTrigger: ['change', 'blur'],
                        rules: [{
                          required: true,
                          message: '请选择工作职务',
                        }]
                      }
                    ]),expression:"[\n                      'jobId',\n                      {\n                        validateTrigger: ['change', 'blur'],\n                        rules: [{\n                          required: true,\n                          message: '请选择工作职务',\n                        }]\n                      }\n                    ]"}],staticStyle:{"width":"100%"},attrs:{"optionFilterProp":"children","disabled":_vm.type==='edit',"placeholder":"请选择工作职务"}},_vm._l((_vm.jobs),function(item){return _c('a-select-option',{key:'job-'+item.id,attrs:{"title":item.name,"value":item.id}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1)],1):_vm._e(),_c('a-row',{staticClass:"form-row",attrs:{"gutter":16}},[_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"角色"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'roleIds',
                      {
                        validateTrigger: ['change', 'blur'],
                        rules: [{
                          required: true,
                          message: '请选择角色',
                        }],
                      }
                    ]),expression:"[\n                      'roleIds',\n                      {\n                        validateTrigger: ['change', 'blur'],\n                        rules: [{\n                          required: true,\n                          message: '请选择角色',\n                        }],\n                      }\n                    ]"}],staticStyle:{"width":"100%"},attrs:{"mode":"multiple","optionFilterProp":"children","placeholder":"请选择角色"}},_vm._l((_vm.roles),function(item){return _c('a-select-option',{key:'role-'+item.id,attrs:{"title":item.name,"value":item.id}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }